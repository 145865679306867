import _ from "lodash";
import { Organization } from "./Organization";
import { UserMetadataDTO as IUserDataDTO } from "./UserMetadata";

const DEFAULT_USERNAME_ABBREVIATION = "UN";

export enum CheckedTool {
  PerspectiveTool = "PerspectiveTool",
  AngleTool = "AngleTool",
}

export enum IOpenUserRole {
  OrganizationAdmin = "organization_admin",
  Pilot = "pilot",
  User = "user",
  // Defines if user has access to organization resources
  OrganizationMaintainer = "organization_maintainer",
}

export const BASE_USER_ROLES = [IOpenUserRole.User, IOpenUserRole.Pilot];

export interface IOrganizationUser {
  id: number;
  firstName: string;
  lastName: string;
  email: string;
  phone: string;
  jobTitle: string;
  confirmed: boolean;
  roles: IOpenUserRole[];
}

export interface UserShort {
  id: number;
  email: string;
}

export interface LoggedUser extends IOrganizationUser {
  organization: Organization;
  approved: boolean;
  modelCount: number;
  userMetadata?: IUserDataDTO;
  stripeRefId: string;
}

export class UserHelpers {
  static hasRole(user: IOrganizationUser, role: IOpenUserRole) {
    return user?.roles?.includes(role);
  }

  static getUserName(user: IOrganizationUser) {
    return Boolean(user.lastName)
      ? `${user.firstName} ${user.lastName}`
      : user.firstName;
  }

  static getOrganizationName(user: LoggedUser) {
    return _.get(user, "organization.name", "");
  }

  /**
   * Getting username abbreviation.
   * For example for 'John Paul' shortcut will be 'JP'
   */
  static getUserNameShortcut = (username: string): string => {
    if (!username) {
      return DEFAULT_USERNAME_ABBREVIATION;
    }
    const letters = username
      .split(" ")
      .map((word) => word[0])
      .join("")
      .toLocaleUpperCase();

    return letters.slice(0, 2);
  };
}
