import React from "react";
import ModelListElement, { unlockUIType } from "./ModelListElement";
import { getModelPageUrl } from "../../../../../../utils/globalUtils";
import { ModelPageViewTypes } from "../../../../../../const/global";
import { ModelAccessType, ModelStatus } from "../../../../../../models/Model";
import { inject, observer } from "mobx-react";
import { IGlobalStore } from "../../../../../../stores/GlobalStore";
import { IModelListActions, IModelListItem } from "../interface";
import { PermissionService } from "../../../../../../services/PermissionService";
import { ServiceType } from "../../../../../../models/ServicePlan";
import { ModelAccessPolicy } from "../../../../ModelAccessPolicy";
import { showUnlockUI } from "./utils";
import ModelBadge from "./components/ModelBadge/ModelBadge";

interface IOrganizationModelListElementPropTypes extends IModelListActions {
  GlobalStore?: IGlobalStore;
  model: IModelListItem;
  onShare: (model: IModelListItem) => void;
  onUnlock: () => void;
}

const OrganizationModelListElement = (
  props: IOrganizationModelListElementPropTypes
) => {
  const { model, GlobalStore, onUnlock } = props;
  const showTacbrowse = PermissionService.hasService(
    GlobalStore.user,
    ServiceType.Tacbrowse
  );

  const accessLocked =
    [
      ModelStatus.Archived,
      ModelStatus.RestoringArchive,
      ModelStatus.Archiving,
    ].includes(model.status) ||
    [ModelAccessType.Archived, ModelAccessType.Expired].includes(
      model.accessType
    );
  const accessType = ModelAccessPolicy.modelPermissionsType(
    GlobalStore.user,
    model.accessType,
    model.accessExpiresAt
  );

  return (
    <ModelListElement
      accessType={accessType}
      accessLocked={accessLocked}
      showUnlockUI={showUnlockUI(model.status, accessType)}
      unlockUIProps={{
        type: unlockUIType(accessType),
        onClick: onUnlock,
      }}
      badge={<ModelBadge status={model.status} accessType={accessType} />}
      showOptions={showTacbrowse}
      showShare={true}
      showHide={false}
      showReveal={false}
      shareContent={
        <div className="w-full flex justify-end">
          <span className="font-bold mr-1">Created by:</span>
          {model.owner.email}
        </div>
      }
      showTacbrowse={showTacbrowse}
      key={model.id}
      onHide={() => {}}
      onReveal={() => {}}
      mapRedirectUrl={getModelPageUrl(model.id, ModelPageViewTypes.Map)}
      modelRedirectUrl={getModelPageUrl(model.id, ModelPageViewTypes.Model)}
      {...props}
    />
  );
};

export default inject("GlobalStore")(observer(OrganizationModelListElement));
