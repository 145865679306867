import {
  ModelAccessType,
  IPublicModel,
  Model,
  ModelPermissionsType as ModelPermissionsType,
  ModelStatus,
} from "../../models/Model";
import { LoggedUser } from "../../models/User";
import { SubscriptionType } from "../payments/payments.model";

export interface IModelAccessPolicyProps {
  user: LoggedUser;
  model: IPublicModel;
}

export class ModelAccessPolicy {
  static isLocked(status: ModelStatus, accessType: ModelAccessType): boolean {
    return (
      [
        ModelStatus.Archived,
        ModelStatus.RestoringArchive,
        ModelStatus.Archiving,
      ].includes(status) ||
      [ModelAccessType.Archived, ModelAccessType.Expired].includes(accessType)
    );
  }

  static isAccesible(model: IPublicModel): boolean {
    return (
      model.accessType === ModelAccessType.Premium ||
      model.accessType === ModelAccessType.Preview
    );
  }

  static modelPermissionsType(
    user: LoggedUser,
    accessType: ModelAccessType,
    modelAccessExpiresAt: string | null
  ): ModelPermissionsType {
    // If the model is archived, expired or premium, return the access type
    // Otherwise check the user's subscription type
    if (
      [
        ModelAccessType.Archived,
        ModelAccessType.Expired,
        ModelAccessType.Premium,
      ].includes(accessType)
    ) {
      return accessType as any;
    }

    const subscriptionType = user?.organization.subscription.type.value;
    if (!subscriptionType) return ModelPermissionsType.Preview;

    if (
      subscriptionType === SubscriptionType.Premium ||
      new Date(modelAccessExpiresAt) > new Date()
    ) {
      return ModelPermissionsType.Premium;
    }

    if (subscriptionType === SubscriptionType.Lite) {
      return ModelPermissionsType.Lite;
    }

    return ModelPermissionsType.Preview;
  }

  static hasModelAccess(user: LoggedUser, model: IPublicModel): boolean {
    return (
      user?.organization?.active || model.accessType === ModelAccessType.Premium
    );
  }

  static canEditModel(user: LoggedUser, model: Model): boolean {
    return user?.id === model?.ownerId;
  }

  static canDownloadAssets(user: LoggedUser, model: IPublicModel): boolean {
    return (
      user?.organization?.active || model.accessType === ModelAccessType.Premium
    );
  }
}
