import React from "react";
import styled from "styled-components";
import ModelListElement from "./ModelListElement";
import { getModelPageUrl } from "../../../../../../utils/globalUtils";
import { ModelPageViewTypes } from "../../../../../../const/global";
import { ISharedOutModelItem } from "../../../../../../models/SharedModel";
import { useTranslation } from "react-i18next";
import ModelListElementShareBy from "./components/ModelListElementShareBy";
import { IAppContext } from "../../../../../interfaces";
import { UserShort } from "../../../../../../models/User";
import { ModelAccessPolicy } from "../../../../ModelAccessPolicy";
import GlobalStore from "../../../../../../stores/GlobalStore";
import ModelBadge from "./components/ModelBadge/ModelBadge";

interface ISharedOutModelListElementPropTypes {
  sharedOutModel: ISharedOutModelItem;
  onDelete: (sharedTo: UserShort) => void;
}

const Label = styled.b`
  font-size: 0.9em;
  margin-bottom: ${(p: IAppContext) => p.theme.skye.sizes.radius};
  color: ${(p: IAppContext) => p.theme.skye.colors.textPrimary};
`;

const SharedOutModelListElement = (
  props: ISharedOutModelListElementPropTypes
) => {
  const { t } = useTranslation();
  const { sharedOutModel } = props;
  const { model } = sharedOutModel;

  const accessLocked = ModelAccessPolicy.isLocked(
    model.status,
    model.accessType
  );
  const accessType = ModelAccessPolicy.modelPermissionsType(
    GlobalStore.user,
    model.accessType,
    model.accessExpiresAt
  );

  return (
    <ModelListElement
      accessType={accessType}
      accessLocked={accessLocked}
      badge={<ModelBadge status={model.status} accessType={accessType} />}
      showOptions={false}
      showShare={false}
      showHide={false}
      showReveal={false}
      showTacbrowse={false}
      model={model}
      shareContent={
        <div>
          <Label>{t("sharedTo")}</Label>
          {sharedOutModel.sharedTo.map((sharedTo) => (
            <ModelListElementShareBy
              key={sharedTo.user.id}
              shareBy={sharedTo.user.email}
              onDelete={() => props.onDelete(sharedTo.user)}
            />
          ))}
        </div>
      }
      onShare={() => {}}
      onHide={() => {}}
      onReveal={() => {}}
      mapRedirectUrl={getModelPageUrl(model.id, ModelPageViewTypes.Map)}
      modelRedirectUrl={getModelPageUrl(model.id, ModelPageViewTypes.Model)}
      {...props}
    />
  );
};

export default SharedOutModelListElement;
