import { memo } from "react";

const VolumeCalculationToolIcon = () => {
  return (
    <svg
      width="32"
      height="32"
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M3.87555 9.00003V23L16 30.0001L28.1244 23V9.00003L16 2L3.87555 9.00003Z"
        stroke="black"
        stroke-width="1.2"
        stroke-miterlimit="10"
      />
      <path
        d="M12.0704 4.2688L24.1279 11.2303V25.3075L15.9999 30.0002L7.87201 25.3075V11.2303L19.9296 4.2688"
        stroke="black"
        stroke-width="1.2"
        stroke-miterlimit="10"
      />
      <path
        d="M8.46036 6.35303L20.1749 13.4496V27.537L15.9999 29.9474L11.8251 27.537V13.4496L23.8282 6.51963"
        stroke="black"
        stroke-width="1.2"
        stroke-miterlimit="10"
      />
      <path
        d="M3.87555 9L16 16L28.1244 9"
        stroke="black"
        stroke-width="1.2"
        stroke-miterlimit="10"
      />
      <path
        d="M3.87555 13.7507L16 20.7508L28.1244 13.7507"
        stroke="black"
        stroke-width="1.2"
        stroke-miterlimit="10"
      />
      <path
        d="M3.87555 18.3269L16 25.3269L28.1244 18.3269"
        stroke="black"
        stroke-width="1.2"
        stroke-miterlimit="10"
      />
      <path
        d="M15.9999 30.0001V16"
        stroke="black"
        stroke-width="1.2"
        stroke-miterlimit="10"
      />
    </svg>
  );
};

export default memo(VolumeCalculationToolIcon);
