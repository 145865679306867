interface WizardStorageData {
  date: Date;
}

class UserDataWizardStorage {
  static readonly KEY = "skye_user_data_wizard_last_open";

  static saveLastOpen(lastOpenDate: Date) {
    if (!lastOpenDate) return;

    const storageData = {
      date: lastOpenDate.toISOString(),
    };

    localStorage.setItem(
      UserDataWizardStorage.KEY,
      JSON.stringify(storageData)
    );
  }

  static getLastOpen(): WizardStorageData | null {
    const data = localStorage.getItem(UserDataWizardStorage.KEY);
    if (!data) return null;

    const parsedData = JSON.parse(data);
    return {
      date: new Date(parsedData.date),
    };
  }
}

export default UserDataWizardStorage;
