import {
  IPublicModel,
  ModelStatus,
  ModelPermissionsType,
} from "../../../../../../models/Model";

export const showUnlockUI = (
  modelStatus: ModelStatus,
  accessType: ModelPermissionsType
): boolean => {
  if (
    modelStatus !== ModelStatus.Finished &&
    modelStatus !== ModelStatus.Archived
  )
    return false;

  if (
    [ModelStatus.Archiving, ModelStatus.RestoringArchive].includes(modelStatus)
  ) {
    return false;
  }

  if (
    [
      ModelPermissionsType.Archived,
      ModelPermissionsType.Expired,
      ModelPermissionsType.Preview,
    ].includes(accessType)
  ) {
    return true;
  }

  return false;
};
