import IDictionary from "../dictionary";

const PASSWORD_FORMULA_TRANSLATION =
  "Password need to have minimum 8 characters and to contain at least one capital and lowercase letter, and at least one digit";

const measurementTypesNames = {
  Area: "Area",
  Point: "Point",
  Height: "Height",
  CoordinatePoint: "Coordinate point",
  CenterPoint: "Center point",
  Distance: "Distance",
  Locator: "Locator",
  RescaleLine: "Rescale line",
  Perspective: "Perspective",
};

const translationEN: IDictionary = {
  ...measurementTypesNames,
  // A
  android: "Android",
  adjustments: "Adjustments",
  adjustmentsEmpty: "Currently you dont have any adjustments",
  activatePremium: "Purchase",
  allowedGPSExtensions: "Allowed extensions: .SRT, .ASS, .TXT, .CSV",
  allowedVideoExtensions: "Allowed extensions: .MP4, .MOV",
  annotationUrl: "Annotation url",
  annotationUrlPlaceholder: "Write url",
  annotationUrlDescriptionPlaceholder: "Write description",
  annotationToolDialogTitle: "Annotation tool",
  annotation_tool_tooltip: "Annotation tool",
  addMembers: "Add members",
  add: "Add",
  accessSectionLabel: "Manage permissions",
  account: "Account",
  accountSettings: "Account settings",
  accountDeletedPageTitle: `We received your request. Will contact with you soon.`,
  accountDeleteInProgressPageTitle: `We're working on your delete request.`,
  all: "All",
  active: "Active",
  altitude: "Altitude",
  area_tool: "Area",
  angle_tool: "Angle",
  addAnnotation: "Add annotation",
  addModel: "Add model",
  annotationName: "Annotation name",
  annotationNamePlaceholder: "Write annotation name",
  advancedDashboardToolsTitle: "Advanced dashboard tools",
  autelMultipartedEmergencyUploadInfo: `There's a known bug with Autel WideBrowse, where multi-part file upload doesn't upload all video files. Please go to backup upload to upload each video file part.`,
  // B
  batchUploadTitle: "Batch upload",
  browseType: "Browse type",
  browseTypePlaceholder: "Select Browse Type",
  browseTypeTitle: "Select Browse Type",
  buy: "Buy",
  buySubscription: "Buy subscription",
  // C
  choosePlatform: "Choose platform",
  chooseIssueReason: "Reason",
  chooseModel: "Choose model",
  chooseMappingType: "Choose file type",
  createPartLabel: "Create part and upload file",
  create: "Create",
  createPart: "Create part",
  confirmOrder: "Confirm order",
  copySessionAddress: "Copy session address",
  copied: "Copied",
  connected: "Connected",
  coupon: "Coupon code",
  contactUs: "Contact Us",
  change_fov_tool: "Change POV",
  caseNumberLabel: "Case number",
  caseNumberPlaceholder: "Write case number...",
  couponValid: "Coupon code is valid",
  couponNotValid: "Coupon code is not valid",
  couponPlaceholder: "Please write coupon code",
  close: "Close",
  clipping_tool: "Clip",
  clouds: "Clouds",
  cameraType: "Camera type",
  cancel: "Cancel",
  confirm: "Confirm",
  copyAddress: `Copy address`,
  comments: `Comments`,
  commentsEmpty: `Add your first comment`,
  commentInputPlaceholder: `Write comment`,
  confirmationEmailInformation: `Account created sucessfully. Please check Your email to activate it`,
  chooseMoviesTitle: "Choose Your Files",
  chooseMoviesDescription: "Please choose video files with .MP4 extension.",
  chooseUniversalUploadFilesTitle: "Choose files",
  chooseUniversalUploadFilesTitleDescription: "Video File",
  created: "Created",
  create_volume_tool: "Volume",
  createdAt: "Created at",
  createSession: "Create session",
  createNewTag: "Create new tag",
  capture_current_report: "Save current view for report",
  capture_current_view: "Save current view",
  capture_top_view: "Save top down view",
  // D
  DjiAvata: `Dji Avata`, // Translation to InteriorDroneTypes
  delete: `Delete`,
  deleteAccount: `Delete account`,
  deleteAccountInputPlaceholder: `Why you want to leave us?`,
  download: `Download`,
  description: `Description`,
  deleteAllLayersDialogTitle: `Do you want to delete all layers?`,
  delete_all_measurements: `Remove all measurements`,
  delete_all_volumes: `Remove all volumes`,
  disconnected: "Disconnected",
  distance_tool: "Distance",
  downloadLazFile: `LAZ file`,
  downloadObjFile: `OBJ - Texture file`,
  downloadTiffFile: `GeoTIFF — 2D Map`,
  downloadLazFileDescription: `LAZ is a compressed format of LAS. LAZ files are usually five to ten times smaller than LAS files. The open source format of LAZ allows it to be rapidly uploaded into most 3D model viewers.`,
  downloadObjFileDescription: `OBJ files represents the coordinates of each vertex, texture maps, polygonal faces, and other object information. It is a texture that can be exported and opened by a wide number of 3D viewers.`,
  downloadTiffFileDescription: `Georeferenced 2D orthographic`,
  lazFileNotAvailable: `LAZ file is not available`,
  descriptionPlaceholder: `Write description...`,
  droneType: `Drone type`,
  dragAndDropOrSelectFiles: `Drop your files here or <strong>browse</strong>`,
  dragAndDropOrSelectFile: `Drop your file here or <strong>browse</strong>`,
  dropFilesHere: "Drop the files here",
  dropFileHere: "Drop file here",
  downloadLazFileName: "skyebrowse_model.laz",
  downloadTiffFileName: "skyebrowse_model.tiff",
  // E
  ["Evo"]: "Evo",
  ["Evo II"]: "Evo II",
  en: "English",
  edit: "Edit",
  editName: "Edit name",
  editPermissions: "Edit permissions",
  emergencyUpload: "Upload",
  emergencyUploadTitle: "upload",
  emergencyUploadDescription:
    "If SkyeBrowse is not accepting the video file that you are trying to upload, backup upload overrides it. Only use this when the video for this model cannot be uploaded normally. Make sure that the video file is the video for this model.",
  emergencyUploadNotAvailable: `Video already uploaded. Backup upload is not available. Please contact with support.`,
  emergencyUploadConfirmationDialogContent: `Please confirm that this is the video file for this model. You cannot change this later.`,
  emergencyUploadConfirmationPartDialogContent: `Please confirm that this is the video file for this specific part. You cannot change this later.`,
  emergencyUploadProblemInformation: `If you have issues uploading your video, try our <strong>emergency</strong> upload feature`,
  emergencyUploadInstruction: `If you have issues uploading your video, try our backup upload feature! Click the card for the model, click the > on the right of "share", and go to backup upload. Make sure to upload the right video!`,
  email: "Email",
  external: "external",
  emailSent: "Email sent",
  error_file_already_uploaded: "File already uploaded",
  error_be_user_already_exist: "User already exists",
  error_be_user_or_password_not_found: "Invalid credentials",
  error_be_user_not_confirmed:
    "Your account has not been confirmed. Please click the activation link",
  error_be_shared_model_already_shared:
    "Model is already shared with current user",
  error_be_shared_model_owner: `You can't share model to Yourself`,
  error_be_shared_model_not_owner: "You are not owner of the model",
  error_be_passwords_not_matched: "Wrong password",
  error_be_new_passwords_not_matched: `The passwords don't match`,
  error_be_unrecognized_token: `Provided token is not valid`,
  error_be_validation_error: `Provided data is not correct`,
  error_be_model_not_found: "Model not found",
  error_be_session_not_found: "Session not found",
  error_be_user_not_found: "User does not exist",
  error_be_no_permission_for_upload_video:
    "You can't upload videos for this model type",
  emailLabel: "Email",
  emailPlaceholder: "Write email",
  export_frames_from_polygon: "Export video frames",
  // F
  failed: "Failed",
  fileUpload: "File upload",
  fileNotProvided: "File not provided",
  fileNotAvailable: "File not available",
  flightDetails: "Flight details",
  filters: "Filters",
  firstNameLabel: "First name",
  firstNamePlaceholder: "Write first name",
  feet: "Feet",
  factualDiagram: "Factual diagram",
  firstOrbitRadius: "First orbit radius",
  firstOrbitAltitude: "First orbit altitude",
  file: "file",
  five_years: "5 years", // Translation for value brom BE
  forgotPasswordQuestion: "Forgot password?",
  freemiumViewerPreviewModeInformation:
    "You have model preview access. Measurements and downloads are disabled.",
  freemiumWidebrowseUploadInformation:
    "<strong>WideBrowse</strong> upload is a premium feature",
  freemiumUploadInformation:
    "Upload of <strong>widebrowse</strong> flights is blocked",
  subscriptionBarFreemiumShortInformation:
    "You are on <strong>Freemium</strong>",
  subscriptionBarFreemiumInformation:
    "Your subscription has expired. You can still make 3D models for free on SkyeBrowse, but measurements are disabled. Stay <strong>Freemium</strong> or",
  subscriptionBarFreeAboutToExpire: "Hurry Up! Your free trial will expire in",
  subscriptionBarPaidAboutToExpire:
    "Hurry Up! Your subscription will expire in",
  // G
  general: "General",
  purchaseThisModel: "Purchase this model",
  generate: "Generate",
  generateReport: "Generate",
  geoTiffNotAvailable: "GeoTiff is not available",
  gpsLogsUploaded: "Logs uploaded",
  gpsLogsNotAvailable:
    "GPS Logs not available. Use the scale tool to set measurements.",
  back: "Back",
  // H
  hide: "Hide",
  hideModelDescription:
    "Hide your model from the main dashboard. You can view hidden models by clicking the drop down menu in the main dashboard. NOTE: This does NOT delete your models.",
  hidden: "Hidden",
  height_tool: "Height",
  hasWrongFileExtension: "has the wrong file extension",
  humidity: "Humidity",
  // I
  "interiorAi.pageTitle": "Interior AI",
  issueReason: "Issue",
  ios: "iOS",
  invitePeopleToSessionModalTitle: "Invite people to session",
  introductionInfoPageTitle: "SkyeBrowse basics",
  information: "Information",
  invite: "Invite",
  inviteMultipleUsers: "Invite :number users",
  inviteOneUser: "Invite 1 user",
  inviteNewUser: "Invite new user",
  interiorTitle: "Interior Mapping",
  interiorDescription:
    "Toggle on if you are recording a cell phone video or flying indoors with a DJI Avata.",
  inQueue: "In Queue",
  // J
  jobTitleLabel: "Job title",
  jobTitlePlaceholder: "Write job title",
  // K
  // L
  label: "Label",
  layers: "Layers",
  live: "Live",
  lite: "Lite",
  liveResults: "Live results",
  linkCopiedToClipboard: "Link copied to clipboard",
  location_point_tool: "GPS",
  loading: "Loading...",
  logout: "Logout",
  login: "Login",
  lastnameLabel: "Last name",
  lastnamePlaceholder: "Write last name",
  length_unit_m: "m",
  length_unit_ft: "ft",
  length_unit_in: "in",
  length_unit_cm: "cm",
  length_unit_mm: "mm",
  length_unit_yd: "yd",
  locked: "Locked",
  // M
  menu: "Menu",
  members: "Members",
  meters: "Meters",
  manual: "manual",
  mapView: "Map view",
  modelView: "Model view",
  measurementsInfoTitle: "Measurement tools",
  measurements_section: "Measurement Tools",
  measure: "Measure",
  measurements: "Measurements",
  "models.2DView": "2D View",
  "models.3DView": "3D View",
  "models.2DGeotiff": "GeoTIFF",
  "models.mapView": "Map View",
  "models.sketch": "Sketch Drawing",
  "models.orthophotoNotAvailable": "Orthophoto is not available",
  "models.orthophotoNotAvailableDescription":
    "Orthophoto is not available for this model",
  "models.orthophotosNotAvailable": "Orthophoto and sketch are not available",
  "models.orthophotosNotAvailableDescription":
    "Orthophoto and sketch are not available for this model",
  month: "1 Month", // Translation for value brom BE
  modelSaved: "Model saved",
  modelScale: "Model scale",
  modelArchiveLabel: "{{ formattedDate }} days before your model is archived",
  modelArchivedLabel: "Model archived",
  modelArchiveTodayLabel: "Model archives today",
  modelArchiveTomorrowLabel: "Model archives tomorrow",
  modelDeepArchivedLabel: "Model archived",
  modelDeleteLabel:
    "{{ formattedDate }} days before your model is permanently archived",
  modelExpireLabel: "{{ formattedDate }} days before your model access expires",
  modelExpireTodayLabel: "Model access expires today",
  modelExpireTomorrowLabel: "Model access expires tomorrow",
  model_not_visible: "Model not visible",
  modelPaymentDialogTitle: "Purchase access",
  modelNotShowingUpQuestion:
    "Is your card not showing up? Click here and take a look at this video explaining how to fix the problem",
  modelNotShowingUpTitle: `What if my models aren't appearing in the dashboard?`,
  modelName: "Model name",
  modelNamePlaceholder: "Write model name...",
  modelUploaded: "Model Uploaded",
  modelCreated: "Model finished",
  modelPublicUrl: "Public url",
  modelPublicAccessDialogInformation:
    "Sharing this publicly will affect your chain of custody of evidence.",
  modelHasPublicAccess: "Model has public access",
  modelHasNoPublicAccess: "Model has no public access",
  modelIsPrivate: "Model is private",
  modelIsPublic: "Model is public",
  modelsListEmptyInfo: "No Items Available",
  models: "Models",
  model: "Model",
  modelCount: "Model count",
  myProfile: "My profile",
  measurementNameDialogTitle: "Change measurement label",
  measurementNameInputLabel: "Measurement name",
  measurementNameInputPlaceholder: "Write measurement name...",
  // N
  "navbar.interiorAi": "Interior AI",
  name: "Name",
  nameLabel: "Name",
  namePlaceholder: "Write name",
  next: "Next",
  newPasswordLabel: "New password",
  newPasswordPlaceholder: "Write password",
  newPasswordRepeatLabel: "Repeat password",
  newPasswordRepeatPlaceholder: "Write password",
  noResults: "There are no results",
  noFileSelected: "File not selected",
  nightVision: "Night Ops",
  // O
  or: "or",
  objFileNotAvailable: "OBJ file is not available",
  objectTree: "Object tree",
  objectTreeEmpty: "Currently you dont have any layers",
  orbitCount: "Orbit count",
  offlineModeTitle: "Offline mode",
  orbitRadius: "Orbit radius",
  offline: "Offline",
  other: "Other",
  owner: "Owner",
  owned: "My models",
  orShareByLink: "Or share with link",
  organization: "Organization",
  organizationMaintainer: "Organization maintainer",
  organizationAccessLabel:
    "Organization access (can see all models made within the organization)",
  organizationLabel: "Organization",
  organizationPlaceholder: "Write organization name",
  organizationUsers: "Organization users",
  organizationName: "Organization name",
  organizationModelCount: "Organization model count",
  oldPassword: "Old password",
  oldPasswordLabel: "Old password",
  oldPasswordPlaceholder: "Write password",
  overallProgress: "Overall progress",
  open_profile_panel: "Slope Map",
  // P
  part: "Flight part",
  pending: "Pending",
  private: "Private",
  previous: "Previous",
  platform: "Platform",
  privateSessionDescription:
    "Only organization members can see and edit this session",
  publicSessionDescription:
    "Anyone on the internet can see this session. Only organization members can edit",
  public: "Public",
  purchase: "Purchase",
  phoneLabel: "Phone",
  phonePlaceholder: "Write phone",
  passwordLabel: "Password",
  passwordChanged: "Password changed",
  permissionsChanged: "Permissions changed",
  passwordPlaceholder: "Write password",
  profile: "Profile",
  profileUpdated: "Profile updated",
  processing: "Processing",
  processing_stuck: "Stuck during processing",
  promptUploadingVideos: `Are you sure you want to leave the page? It will stop all your uploads.`,
  premium: "Premium",
  pressure: "Pressure",
  pinColor: "Color",
  price: "Price",
  "pricing.requestQuote": "Request Quote",
  "pricing.monthly": "Monthly",
  "pricing.annual": "Annual",
  "pricing.name.freemium": "Freemium",
  "pricing.name.payPerModel": "Pay Per Model",
  "pricing.name.subscription": "Premium",
  "pricing.name.liteSubscription": "Lite",
  "pricing.processing_speed_standard": "Standard",
  "pricing.processing_speed_priority": "Priority",
  "pricing.processing_speed_ultra": "Ultra",
  "pricing.accuracy_medium": "Medium (12 inches)",
  "pricing.accuracy_high": "High (2 inches)",
  "pricing.accuracy_ultra": "Ultra (0.25 inches)",
  "pricing.option.applied": "Applied",
  "pricing.option.notApplied": "Not applied",
  "pricing.option.optional": "Optional",
  "pricing.option.processingSpeed": "Processing Speed",
  "pricing.option.accuracy": "Resolution and Accuracy",
  "pricing.option.toolsSharingAndExport": "Standard tools, sharing and export",
  "pricing.option.professionalTools": "Professional tools",
  "pricing.option.interiorAiDesigner": "Interior AI Designer",
  "pricing.option.cloudStorage": "Cloud storage",
  "pricing.option.support": "Support",
  "pricing.option.cjisCompliance": "CJIS Compliance & Chain of Custody",
  "pricing.option.price": "Price",
  "pricing.cloudStorage.oneMonth": "1 month",
  "pricing.cloudStorage.sixMonths": "6 months",
  "pricing.cloudStorage.fiveYears": "5 years",
  "pricing.cloudStorage.oneYear": "1 year",
  "pricing.support.basic": "Basic",
  "pricing.support.email": "Email",
  "pricing.support.ceo_phone": "CEO's Cell Phone Number",
  "paymentPollDialog.failed": "Payment failed",
  "paymentPollDialog.success": "Payment processed successfully",
  "paymentPollDialog.title": "Payment status",
  "paymentPollDialog.description":
    "Please wait. We are processing your payment",
  "payment.model.subscribeDescription":
    "Subscribe to Lite (2 week free trial) to unlock all models.",
  // R
  reveal: "Reveal",
  revealModel: "Reveal model",
  revealModelDescription: "Move your model to the main dashboard",
  reportIssue: "Report issue",
  reportIssueShareModelWithSupport:
    "Share model data with us to resolve this issue quicker",
  remove: "Remove",
  resultsCount: "Results: :count Models",
  role: "Role",
  rain: "Rain",
  roadmap: "Map",
  readPrivacyPolicy: "Read our privacy policy",
  register: "Register",
  registerPageRedirectQuestion: `Don't have an account?`,
  requestFailed: "Request failed",
  requestCanceled: "Request canceled",
  requestNetworkError: "Request failed. Network connection issue",
  resetToDefault: "Reset to default",
  resetPassword: "Reset password",
  resetPasswordWriteEmailInstruction: `Write your email to reset password. We'll send you a reset link shortly.`,
  reports_section: `Report Tool`,
  removeAllVolumes: "Remove all volumes",
  removeAllMeasurements: "Remove all",
  // S
  "subscription.upgradeInfoTitle":
    "Upgrade to access the full potential of SkyeBrowse",
  "subscription.lite.upgradeInfoTitle": "Lite",
  "subscription.lite.upgradeInfoDescription":
    "Full access to standard tools and exports. Enjoy unlimited 3D modeling",
  "subscription.upgradeInfoDescription":
    "Unlock Interior AI Designer, measurement tools, export 3D files, and more with SkyeBrowse Lite.",
  "subscription.subscribeNowBtn": "✨ Subscribe Now",
  "subscription.extend": "Extend Subscription",
  "subscription.featureNotAvailableForCurrentPlan":
    "Feature not available for current plan",
  scale: "Scale",
  startUpload: "Start upload",
  skyebrowse: "Skyebrowse",
  search: "Search",
  searchModel: "Search by name",
  searchUser: "Search user",
  selectOption: "Select option",
  selectTag: "Select tag",
  selectDroneType: "Select drone type",
  sessionsListEmptyTitle: "You dont have any sessions",
  sessionsListEmptyDescription: "Click button below and create first one",
  sessions: "Sessions",
  sessionAddress: "Session address",
  sessionOwner: "Host",
  sessionCreated: "Session finished",
  sessionSettings: "Session settings",
  sessionCreationFailed: "Session not finished",
  sessionName: "Session name",
  sessionNamePlaceholder: "Write session name",
  satellite: "Satellite",
  shareViaLink: "Share by link",
  save: "Save",
  saveFrame: "Save frame",
  send: "Send",
  secondOrbitAltitude: "Second orbit altitude",
  secondOrbitRadius: "Second orbit radius",
  six_months: "6 months", // Translation for value brom BE
  sideScan: "Side scan",
  signUp: "Sign Up",
  signInPageMainText: "The Best Drone Software for First Responders",
  signUpHeaderInfo:
    "You can also make 3D models with just one tap! Sign up for a free 2-week trial",
  share: "Share",
  shareTo: "Share to SkyeBrowser",
  sharedTo: "Shared to",
  shareToSocialMedia: "Share to social media",
  sharedBy: "Shared by",
  sharedToMe: "Shared to me",
  sharedToOthers: "Shared to others",
  shareModel: "Share to SkyeBrowser",
  shareModelDescription: "Share your model with a SkyeBrowse user",
  showDetails: "Details",
  snow: "Snow",
  status: "Status",
  "status.restoringArchive": "Restoring",
  "status.archiving": "Archiving",
  showMeasurements: "Show measurements",
  showAreaLabelsLabel: "Show area labels",
  showEdgeLabelsLabel: "Show edge labels",
  switch_measurement_unit: "Metric / Imperial",
  streamNotAvailable: "Video stream not available",
  streamsNotAvailable: "Video streams not available",
  screenshots_section: "Screenshot",
  screenshot_created: "View saved",
  screenshot_failed: "View failed to save",
  subscribe: "Subscribe",
  subscription: "Subscription",
  "subscription.freemium": "Freemium",
  "subscription.renewNow": "✨ Renew now",
  // T
  tutorials: "Tutorials",
  textureLabel: "Hyper",
  text_label_tool: "Annotate",
  textureLoading: "Loading hyper",
  texturesNotAvailable: "Hyper not available",
  temperature: "Temperature",
  temperatureFeelsLike: "Feels like",
  toProceedYouNeedToAcceptTermsOfUse:
    "To proceed, you need to accept our Terms of Use",
  termsOfUse: "Terms of use",
  toastCommentCreated: "Comment finished",
  toastCommentNotCreated: "Comment not finished",
  toastPublicModelNotAvailable: "Model not available",
  toastModelUnshared: "Model unshared",
  toastModelShared: "Model shared",
  toastWrongExtensionCount: "Invalid file extension",
  toastVideosNotMatchedUseEmergencyUpload:
    "Some videos not recognized. Please use backup upload",
  toastVideosUploadedCount: "Some video have already been uploaded",
  toastVideosNotMatchedModelsCount: `Some videos did not match your models`,
  toastVideosNotAllowedCount: `WideBrowse uploads are not supported on Freemium.`,
  toastFramesNotAvailable: "Frames not available",
  toastSetCenterPoint: "Please specify center point before create a report",
  toastReportGenerated: "Report generated",
  toastReportNotGenerated: "Report not generated",
  toastSessionUpdated: "Session updated",
  toastModelNotAssignedToSession: "Please assign model to session",
  toggle_topography_map: `Toggle topography map`,
  tools: "Tools",
  tooltipBirdView:
    "Bird View - Use WASD or arrow keys to navigate around the model. Hold down click and move your mouse to turn",
  tooltipFullscreen: "Set fullscreen mode",
  tooltipFitModelToScreen: "Fit model to screen",
  tooltipScrollToPointMode: "Scroll to point mode",
  tooltipMovementModeIndicator:
    "Movement mode indicator. Press space or right mouse button to move",
  tooltipMeasurementsSection: "Measurement Tools",
  tooltipToolNotAllowedForTextureView: "Tool not allowed for hyper view",
  tooltipsReportsSection: "Factual Diagram",
  tooltipAngleToolTitle: "How to use the angle tool",
  tooltipAngleToolDescription:
    "Left click around the 3D model to determine angles.",
  tooltipPerspectiveToolTitle: "How to use the POV tool",
  tooltipRotateModelTool:
    "Reorient: Resolve a tilted model based on a known 'up-down' orientation",
  tooltipRotateYTool: "Flip model vertically",
  tooltipPerspectiveToolDescription:
    "Select a point in the 3D model with the POV tool to view from that perspective.",
  toastCenterPointNotSet: "Please specify center point before create a report",
  tooltipAnnotationPoint: "Annotate",
  tooltipCenterPoint: "Reference point",
  tooltipSlopeTool: "Determine slope angle relative to ground level",
  tooltipRescaleModelTool:
    "Select a known measurement to scale the model accordingly",
  // U
  url: "Url",
  unlock: "Unlock",
  unknown: "Unknown", // Translation to InteriorDroneTypes
  UnknownDrone: "Other", // Translation to InteriorDroneTypes
  uploadVideoFile: "Upload video file",
  upload: "Upload",
  usersLimitExceededInformation:
    "You exceeded users limit. To fix that issue, please contact with sales team",
  uploadFailed: "Upload failed",
  uploadVideoDialogHeading: "Please choose Your movies",
  uploadVideoDialogDescription:
    "You can drag and drop Your movies to selected area",
  uploadVideoDialogSubscriptionExpiredDescription: `You can't upload videos at the moment. Please contact bobby@skyebrowse.com`,
  upgrade: "Upgrade",
  unlockModelAccess: "Access Model",
  unlockModelTitle: "Buy model",
  unlockModelDescription:
    "Get access to measurement tools, downloads, and more",
  ultra: "Ultra",
  user: "User",
  userRegistered: "User registered",
  uploadVideoForModels: "Upload videos for Your models",
  "upload.unknownFileExtension": "Unknown file extension", // Translation to InteriorDroneTypes
  "upload.freemiumSizeExceeded":
    "Some files exceeded maximum size for Freemium users. Current maximum size is 2gb.", // Translation to InteriorDroneTypes
  "upload.defaultUniversalModelName": "New Universal 3D Model",
  "upload.defaultInteriorModelName": "New Interior 3D Model",
  "upload.dialog.universalUploadLabel": "Universal Upload",
  "upload.dialog.universalUploadDescription":
    "Upload videos created outside of the SkyeBrowse App (DJI, Autel, Draganfly, GoPro, cell phone, or other video sources)",
  "upload.dialog.regularMappingLabel": "App Upload",
  "upload.dialog.regularUploadDescription":
    "Upload videos created by the SkyeBrowse Flight App",
  "upload.dialog.interiorMappingUploadLabel": "Interior Mapping",
  "upload.dialog.interiorMappingUploadDescription":
    "Upload videos of interior spaces for a room 3D model (DJI Avata, cell phone, small drones, etc)",
  unshareModel: "Unshare",
  unshareModelDescription: "Do You want to unshare model?",
  uploadVideo: "Upload video",
  uploadFiles: "Upload files",
  userName: "Name",
  userEmail: "Email",
  userModelCount: "User model count",
  unitOfMeasure: "Unit of measure",
  userDeleteAccount: `Please confirm by typing in ':phrase' below`,
  userDeletePageHeading: `We don't want you to leave`,
  userDeletePageDescription: `But if you're sure you want to delete your account, click the button below to contact us. We'll get back to you as soon as possible.`,
  userDeleteAccountPlacholder: "Write confirmation phrase",
  // W
  week: "1 Week", // Translation for value brom BE
  web: "Web Browser",
  widebrowse: "Widebrowse",
  welcomeBack: "Welcome back",
  weather: "Weather",
  weatherDetailsNotAvailable: "Weather details are not available",
  windSpeed: "Wind speed",
  windDegree: "Wind degree",
  // V
  visibility: "Visibility",
  video: "Video",
  videoUploded: "Video uploaded",
  videoNotUploadedInformation: `Video for this model is currently not uploaded`,
  videoTutorials: "Video tutorials",
  videoDetails: "Video",
  videoTutorial: "Video tutorial",
  validateErrorPhone: `Phone number it's not correct`,
  validationErrPhone: `Phone number it's not correct`,
  validationErrNumber: `Value it's not a number`,
  validationErrorWrongEmail: "Wrong email",
  validateErrorEmail: "Wrong email",
  validateErrorName: "Wrong name",
  validationErrUrl: "Wrong url",
  validateErrorFirstName: "First name is required",
  validateErrorLastName: "Last name is required",
  validateErrorOrganization: "Wrong organization name",
  validateErrorPassword: PASSWORD_FORMULA_TRANSLATION,
  validateErrorOldPassword: "Old password is not filled",
  validationErrorPassword: PASSWORD_FORMULA_TRANSLATION,
  validateErrorNewPassword: PASSWORD_FORMULA_TRANSLATION,
  validationErrorNotEmpty: "Value can not be empty",
  validationErrorRepeatPasswordDifferent: `Your passwords don't match`,
  validateErrorPasswordRepeat: `Your passwords don't match`,
  validateErrorNewPasswordRepeat: `Your passwords don't match`,
  validateErrorShortDescription: `Description must be maximum 255 characters long`,
  viewSession: "View session",
  volume: "Volume",
  viewModel: "View model",
  videoNotMatchModel: `The provided video does not match any of your models`,
  videoAlreadyUploaded: `The provided video has already been uploaded`,
  volume_highlight_option: "Highlight",
  volume_inside_option: "Inside",
  volume_outside_option: "Outside",
  // R
  registrationSourceLabel: "How do you know about us?",
  recover: "Recover",
  reset: "Reset",
  rescaleModelConfirmButton: "Rescale",
  rescaleModelPlaceholder: "Write real value",
  rescaleModelTitle: "Known Measurement",
  rescaleModelLabel: "Real value",
  responseError422: "Provided data is not correct",
  responseError409: "Item already exists",
  responseError404: "Item does not exist",
  responseError403: "Access forbidden",
  responseErrorRequestFailed: "Request failed",
  // X
  // Y
  // Z
  ok: "ok",
};

export default translationEN;
