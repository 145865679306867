import React, { ReactNode, useEffect, useState } from "react";
import SkyebrowseAuthPageLayout from "../layout";
import Button from "../../../shared/buttons/Button";
import InformationBox from "../../../shared/components/InformationBox/InformationBox";
import { RouteComponentProps } from "react-router-dom";
import { AUTH_ROUTES } from "../../../routes";
import RegisterTokenInitForm from "../../templates/register-form";
import { CouponService } from "../../../../services/CouponService";
import CircleLoader from "../../../shared/components/CircleLoader";
import { MessageService } from "../../../../services/MessageService";

interface RegisterWithCouponPageProps
  extends RouteComponentProps<IMatchParams> {}

interface IMatchParams {
  couponCode: string;
}

const RegisterWithCouponCodePage = ({
  history,
  match,
}: RegisterWithCouponPageProps) => {
  const [loading, setLoading] = useState(true);
  const [couponCode, setCouponCode] = useState(match.params.couponCode);
  const [registered, setRegistered] = useState(false);
  const [couponValid, setCouponValid] = useState(false);

  useEffect(() => {
    const validateToken = async () => {
      CouponService.validateCoupon(couponCode)
        .then((response) => {
          if (response?.data) {
            setCouponValid(true);
            return;
          }

          setCouponValid(false);
          MessageService.error("Coupon code is invalid.");
        })
        .catch((error) => {
          if (error?.data?.message) {
            MessageService.error(error.data.message);
          }
        })
        .finally(() => {
          setLoading(false);
        });
    };

    validateToken();
  }, []);

  const onRegistered = () => {
    setRegistered(true);
  };

  const goToLogin = () => {
    history.push(AUTH_ROUTES.login);
  };

  return (
    <SkyebrowseAuthPageLayout
      title=""
      pageType="finishRegisterByToken"
      contentSize="lg"
    >
      {loading ? (
        <div className="w-full flex justify-center">
          <CircleLoader size={50} color="primary" />
        </div>
      ) : (
        <>
          {registered ? (
            <InformationBox
              transparent
              icon="success"
              title={"Registration success"}
              description="Please check your email to verify your account."
              button={<Button onClick={goToLogin}>Go to login</Button>}
            ></InformationBox>
          ) : (
            <>
              <div className="max-w-md flex justify-center flex-col items-center w-full mx-auto">
                <h1 className="mb-20 text-4xl font-bold text-center">
                  {registered ? (
                    ""
                  ) : (
                    <span>
                      Make unlimited 3D <br />
                      models for free!
                    </span>
                  )}
                </h1>
                <RegisterTokenInitForm
                  couponCode={couponValid ? couponCode : undefined}
                  onRegistered={onRegistered}
                />
              </div>
            </>
          )}
        </>
      )}
    </SkyebrowseAuthPageLayout>
  );
};

export default RegisterWithCouponCodePage;
