import React from 'react'
import {
  ANGLE_TOOL,
  AREA_TOOL,
  SLOPE_TOOL,
  CENTER_POINT,
  FIELD_OF_VIEW_TOOL,
  CREATE_VOLUME_TOOL,
  DELETE_ALL_TOOL,
  DISTANCE_TOOL,
  OPEN_PROFILE_PANEL,
  RESCALE_TOOL,
  SWITCH_TOPOGRAPHY_MAP,
  PERSPECTIVE_TOOL,
  ANNOTATION_TOOL,
  LOCATION_POINT_TOOL,
  VOLUME_CALCULATION_TOOL,
} from "views/viewer/const";
import BasementAngleIcon from "../Icons/BasementAngleIcon";
import AreaIcon from "../Icons/AreaIcon";
import CloseIcon from "../Icons/CloseIcon";
import DistanceIcon from "../Icons/DistanceIcon";
import ProfilePanelIcon from "../Icons/ProfilePanelIcon";
import ReferencePointIcon from "../Icons/ReferencePointIcon";
import TextIcon from "../Icons/TextIcon";
import TopographyMapIcon from "../Icons/TopographyMapIcon";
import VolumeIcon from "../Icons/VolumeIcon";
import FieldOfViewToolIcon from "../Icons/FieldOfViewToolIcon";
import ScaleIcon from "../Icons/ScaleIcon";
import AngleIcon from "../Icons/AngleIcon";
import ReorientIcon from "../Icons/ReorientIcon";
import LocationToolIcon from "../Icons/LocationToolIcon";
import VolumeCalculationToolIcon from "../Icons/VolumeCalculationToolIcon";

interface IToolIconPropTypes {
  toolId: string;
}

const ToolIcon = ({ toolId }: IToolIconPropTypes) => {
  switch (toolId) {
    case ANNOTATION_TOOL.id:
      return <TextIcon />;
    case SLOPE_TOOL.id:
      return <BasementAngleIcon />;
    case ANGLE_TOOL.id:
      return <AngleIcon />;
    case LOCATION_POINT_TOOL.id:
      return <LocationToolIcon />;
    case DISTANCE_TOOL.id:
      return <DistanceIcon />;
    case AREA_TOOL.id:
      return <AreaIcon />;
    case OPEN_PROFILE_PANEL.id:
      return <ProfilePanelIcon />;
    case SWITCH_TOPOGRAPHY_MAP.id:
      return <TopographyMapIcon />;
    case CREATE_VOLUME_TOOL.id:
      return <VolumeIcon />;
    case RESCALE_TOOL.id:
      return <ScaleIcon />;
    case CENTER_POINT.id:
      return <ReferencePointIcon />;
    case DELETE_ALL_TOOL.id:
      return <CloseIcon />;
    case FIELD_OF_VIEW_TOOL.id:
      return <FieldOfViewToolIcon />;
    case PERSPECTIVE_TOOL.id:
      return <ReorientIcon />;
    case VOLUME_CALCULATION_TOOL.id:
      return <VolumeCalculationToolIcon />;
    default:
      return null;
  }
};

export default ToolIcon;
