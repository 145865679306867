import { makeAutoObservable } from "mobx";
import { MessageService } from "services/MessageService";
import _ from "lodash";
import type { LoggedUser } from "models/User";
import { IOpenUserRole } from "models/User";
import type { UserMetadata } from "models/UserMetadata";
import AuthService from "services/AuthService";
import { UserService } from "services/UserService";

type IToastType = "success" | "error" | "info" | "warning";

export interface IGlobalStore {
  redirectRoute: string;
  mobileNavOpen: boolean;
  userLoaded: boolean;
  user: LoggedUser;
  uploadDialogOpen: boolean;
  showViewerAreaLoader: boolean;
  subscriptionBarVisible: boolean;
  addToast: (message: string) => void;
  openUploadDialog: () => void;
  closeUploadDialog: () => void;
  isOwner: () => boolean;
  isPilot: () => boolean;
  isPremium: () => boolean;
  isLoggedIn: () => boolean;
  logout: () => void;
  toggleMobileNav: (open: boolean) => void;
  setRedirectRoute: (path: string) => void;
  getUser: () => Promise<LoggedUser | null>;
  setUser: (user: LoggedUser) => void;
  setSubscriptionBarVisibility: (isVisible: boolean) => void;
}

class GlobalStore implements IGlobalStore {
  redirectRoute: string = "";
  mobileNavOpen: boolean = false;
  subscriptionBarVisible: boolean = false;
  // Identifier if user request was already done
  userLoaded: boolean = false;
  user: LoggedUser;
  uploadDialogOpen = false;
  showViewerAreaLoader = false;

  constructor() {
    makeAutoObservable(this);
  }

  setRedirectRoute(path: string) {
    this.redirectRoute = path;
  }

  addToast(toastContent: string, type: IToastType = "success") {
    MessageService.show({ type, text: toastContent });
  }

  toggleMobileNav(open: boolean) {
    this.mobileNavOpen = open;
  }

  setUser(user: LoggedUser) {
    this.user = user;
    this.userLoaded = true;
  }

  /**
   * MobX sometimes doesn't update user object and we need to force
   * to check id to see if user is logged in
   */
  isLoggedIn() {
    return !!this.user?.id;
  }

  /**
   * Checking if logged user is an a owner of organization
   */
  isOwner() {
    if (!this.user || !this.user.organization) return false;
    return this.user.id === this.user.organization.ownerId;
  }

  /**
   * Describing if current user it's pilot
   */
  isPilot() {
    return this.user?.roles.includes(IOpenUserRole.Pilot);
  }

  /**
   * Describes whether user is on paid subscription
   */
  isPremium() {
    return this.user?.organization?.active;
  }

  getSavedData(): UserMetadata {
    return _.get(this.user, "userMetadata.data", {});
  }

  setSubscriptionBarVisibility(isVisible: boolean) {
    this.subscriptionBarVisible = isVisible;
  }

  /**
   * Getting user, if is not loaded making initial request
   * Method to use in high order components
   */
  async getUser(): Promise<LoggedUser | null> {
    if (!this.userLoaded) {
      try {
        const response = await UserService.getLoggedUser();
        this.setUser(response.data);
      } catch (error) {
        // Err
      }
    }

    return this.user;
  }

  logout() {
    this.user = null;
    AuthService.signOut();
  }

  openUploadDialog() {
    this.uploadDialogOpen = true;
  }

  closeUploadDialog() {
    this.uploadDialogOpen = false;
  }
}

export default new GlobalStore();
