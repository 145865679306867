import { memo } from "react";
import { ModelPermissionsType } from "../../../../../../../../models/Model";
import { useTranslation } from "react-i18next";
import AccessBadge from "../../../../../../../shared/components/badges/AccessBadge/AccessBadge";
import LockIcon from "../../../../../../../shared/Icons/LockIcon";
import ArchiveIcon from "../../../../../../../shared/Icons/ArchivedIcon";
import PremiumBadge from "../../../../../../../shared/components/badges/PremiumBadge/PremiumBadge";

interface IModelAccessBadgePropTypes {
  accessType: ModelPermissionsType;
}

const ModelAccessBadge = ({ accessType }: IModelAccessBadgePropTypes) => {
  const { t } = useTranslation();

  switch (accessType) {
    case ModelPermissionsType.Premium:
      return <PremiumBadge>{t("premium")}</PremiumBadge>;
    case ModelPermissionsType.Lite:
      return <PremiumBadge>{t("lite")}</PremiumBadge>;
    case ModelPermissionsType.Archived:
      return (
        <AccessBadge color="danger" startIcon={<ArchiveIcon />}>
          {t("archived")}
        </AccessBadge>
      );
    case ModelPermissionsType.Expired:
      return <AccessBadge startIcon={<LockIcon />}>{t("locked")}</AccessBadge>;
    default:
      return null;
  }
};

export default memo(ModelAccessBadge);
