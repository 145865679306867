import {
  ModelStatus,
  ModelPermissionsType,
} from "../../../../../../../../models/Model";
import ModelAccessBadge from "./ModelAccessBadge";
import ModelStatusBadge from "./ModelStatusBadge";

interface IModelBadgePropTypes {
  status: ModelStatus;
  accessType: ModelPermissionsType;
}

const ModelBadge = ({ status, accessType }: IModelBadgePropTypes) => {
  if ([ModelStatus.RestoringArchive, ModelStatus.Archiving].includes(status)) {
    return <ModelStatusBadge status={status} />;
  }

  if (accessType) {
    return <ModelAccessBadge accessType={accessType} />;
  }

  return null;
};

export default ModelBadge;
