import React, { useState } from "react";
import InformationBox from "../../../shared/components/InformationBox/InformationBox";
import { withRouter } from "react-router-dom";
import RegisterTokenInitForm from "../../templates/register-form";

const RegisterTokenInitIframePage = () => {
  const [registered, setRegistered] = useState(false);

  const onRegistered = () => {
    setRegistered(true);
  };

  return registered ? (
    <InformationBox
      transparent
      icon="success"
      title={"Registration success"}
      description="Please check your email to verify your account."
    ></InformationBox>
  ) : (
    <RegisterTokenInitForm onRegistered={onRegistered} />
  );
};

export default withRouter(RegisterTokenInitIframePage);
