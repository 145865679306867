import HttpService from "./HttpService";
import API from "const/api";

interface EditUserDTO {
  phone: string;
  jobTitle: string;
  firstName: string;
  lastName: string;
}

export class UserService {
  static async getLoggedUser() {
    const url = API.getLoggedUser;
    return HttpService.get(url, null);
  }

  static async updateLoggedUser(dto: EditUserDTO) {
    const url = API.getLoggedUser;
    return HttpService.patch(url, dto);
  }
}
