import { UserMetadataDTO, UserMetadata } from "models/UserMetadata";
import HttpService, { IResponse } from "./HttpService";
import { getEnvVariables } from "../env";

const env = getEnvVariables();
const metadataUrl = `${env.API_V3_URL}v3/users/me/metadata`;

export class UserMetadataService {
  static async getMetadata(): Promise<IResponse<UserMetadataDTO | null>> {
    return HttpService.get(metadataUrl);
  }

  static async updateUserMetadata(
    newUserSavedData: UserMetadata
  ): Promise<UserMetadataDTO | null> {
    const body: UserMetadataDTO = { metadata: newUserSavedData };

    try {
      await HttpService.put(metadataUrl, body);
      return {
        metadata: newUserSavedData,
      };
    } catch (error) {
      return null;
    }
  }
}
