import React from "react";
import InputGroup from "../../../shared/form/InputGroup";
import { ITranslationFields } from "../../../../locale/dictionary";
import SelectDropdown from "../../../shared/components/Dropdown/SelectDropdown";
import { useTranslation } from "react-i18next";
import Label from "../../../shared/typography/Label";

export interface UserDataWizardFormValues {
  firstName: string;
  lastName: string;
  phone: string;
  jobTitle: string;
  organizationName: string;
  registrationSource: string;
}
export interface UserDataWizardFormProps {
  values: UserDataWizardFormValues;
  onChange: (values: UserDataWizardFormValues) => void;
}

const inputs = [
  {
    name: "firstName",
    label: "firstNameLabel",
    placeholder: "firstNamePlaceholder",
    required: true,
  },
  {
    name: "lastName",
    label: "lastnameLabel",
    placeholder: "lastnamePlaceholder",
    required: true,
  },
  {
    name: "phone",
    label: "phoneLabel",
    placeholder: "phonePlaceholder",
    required: true,
  },
  {
    name: "organizationName",
    label: "organizationName",
    placeholder: "organizationPlaceholder",
    required: true,
  },
  {
    name: "jobTitle",
    label: "jobTitleLabel",
    placeholder: "jobTitlePlaceholder",
    required: true,
  },
];

const sourceOptions = [
  {
    value: "google",
    title: "Google",
  },
  {
    value: "linkedin",
    title: "Linkedin",
  },
  {
    value: "facebook",
    title: "Facebook",
  },
  {
    value: "tiktok",
    title: "TikTok",
  },
  {
    value: "instagram",
    title: "Instagram",
  },
  {
    value: "pilot-institute",
    title: "Pilot Institute",
  },
  {
    value: "friends",
    title: "Friends",
  },
  {
    value: "other",
    title: "Other",
  },
];

const UserDataWizardForm = ({ values, onChange }: UserDataWizardFormProps) => {
  const formValues = values;
  const { t } = useTranslation();

  const handleInputChange = (fieldName: string) => (e: any) => {
    onChange({ ...values, [fieldName]: e.target.value });
  };

  const handleSourceChange = (sourceValue: string) => {
    onChange({ ...values, registrationSource: sourceValue });
  };

  return (
    <form>
      <ul className="grid grid-cols-2 gap-x-4 gap-y-6">
        {inputs.map((input) => (
          <li key={input.name}>
            <InputGroup
              required={input.required}
              bordered
              inputProps={{
                name: input.name,
                value: formValues[input.name],
                placeholder: input.placeholder as ITranslationFields,
                onChange: handleInputChange(input.name),
              }}
              label={input.label as ITranslationFields}
            />
          </li>
        ))}
        <div className="flex h-full w-full items-start flex-col">
          <Label>{t("registrationSourceLabel")}</Label>
          <SelectDropdown
            currentValue={values.registrationSource}
            onSelect={(newValue: string) => handleSourceChange(newValue)}
            placeholder="Select source type"
            options={sourceOptions.map((option) => {
              return {
                value: option.value,
                title: option.title,
              };
            })}
          />
        </div>
      </ul>
    </form>
  );
};

export default UserDataWizardForm;
