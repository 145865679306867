import { useState } from "react";
import useAuthRequests from "../hooks/useAuthRequests";
import { useTranslation } from "react-i18next";
import { isEmail } from "../../shared/utils/validationUtils";
import InputGroup from "../../shared/form/InputGroup";
import Label from "../../shared/typography/Label";
import SkyeCheckbox from "../../shared/form/SkyeCheckbox";
import Button from "../../shared/buttons/Button";
import { PRIVACY_POLICY_URL } from "../../../const/global";

interface RegisterTokenInitFormProps {
  onRegistered: () => void;
  couponCode?: string;
}

const RegisterTokenInitForm = ({
  onRegistered,
  couponCode,
}: RegisterTokenInitFormProps) => {
  const authReq = useAuthRequests();
  const [isLoading, setIsLoading] = useState(false);
  const [errorMessage, setErr] = useState(null);
  const { t } = useTranslation();
  const [submitted, setSubmitted] = useState(false);
  const [marketingChecked, setMarketingChecked] = useState(false);
  const [state, setState] = useState({
    email: "",
  });
  const { email } = state;
  const validationErr = isEmail(email, t);

  const onEmailChange = (e: any) => {
    const { value } = e.target;
    setState((old) => ({ ...old, email: value }));
  };

  const handleSubmit = () => {
    setSubmitted(true);
    setErr(null);

    if (validationErr || isLoading || !marketingChecked) return;

    setIsLoading(true);
    authReq
      .registerWithToken(email, couponCode || "")
      .then(() => {
        setIsLoading(false);
        onRegistered();
      })
      .catch((err) => {
        console.log(err);
        setIsLoading(false);
        setErr(
          err?.response?.data?.message || err?.message || "Something went wrong"
        );
      });
  };

  return (
    <form className="w-full text-left">
      <InputGroup
        bordered
        fullWidth
        label={"email"}
        inputProps={{
          id: "login-email",
          value: email,
          placeholder: "emailPlaceholder",
          onKeyPress: (ev) => {
            if (ev.key === "Enter") {
              handleSubmit();
              ev.preventDefault();
            }
          },
          onChange: onEmailChange,
        }}
      />
      {submitted && validationErr && (
        <Label color="danger" className="mt-2">
          {t(validationErr)}
        </Label>
      )}
      {!!errorMessage && (
        <Label color="danger" className="mt-2">
          {errorMessage}
        </Label>
      )}
      <div className="mb-8">
        <SkyeCheckbox checked={marketingChecked} onChange={setMarketingChecked}>
          By checking this box, I agree to{" "}
          <a
            href={PRIVACY_POLICY_URL}
            target="_blank"
            className="font-primary-500 font-medium underline"
          >
            SkyeBrowse's Terms of Use and Privacy Policy
          </a>{" "}
          and to receive marketing
        </SkyeCheckbox>
      </div>
      <div className="button-wrapper w-full">
        <Button
          id="login-button"
          fullSize
          onClick={handleSubmit}
          loading={isLoading}
          disabled={!!validationErr || isLoading || !marketingChecked}
        >
          <>{t("register")}</>
        </Button>
      </div>
    </form>
  );
};

export default RegisterTokenInitForm;
