import React from 'react'
import { useTranslation } from "react-i18next";
import styled from 'styled-components';
import { IAppContext } from "../../../interfaces";
import ArrowDownIcon from "../../Icons/ArrowDownIcon";
import Box, { BoxCentered } from '../Box';
import SkyeDropdown, { DropdownWrapper } from './Dropdown';
import DropdownItem from './DropdownItem';
import classNames from "classnames";

export interface ISelectOption {
  icon?: any;
  title: string;
  description?: string;
  value: any;
}

interface ISelectDropdownProptypes {
  options: ISelectOption[];
  currentValue: any;
  placeholder: any;
  onSelect: (value: any) => void;
}

const IconWrapper = styled(BoxCentered)`
  width: 44px;
  height: 44px;
  padding: 0 22px 0 0;
  align-items: center;
  justify-content: center;
`;
const ContentWrapper = styled.div``;
const Title = styled.div`
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  margin-bottom: 8px;
`;
const Description = styled.div`
  font-weight: normal;
  font-size: 12px;
  line-height: 140%;
`;
const SelectContainer = styled(Box)`
  height: 42px;
  border: ${(p: IAppContext) => p.theme.skye.borders.primary};
  border-radius: ${(p: IAppContext) => p.theme.skye.sizes.radius};
  padding: 0 20px;
  align-items: center;
  background-color: ${(p: IAppContext) => p.theme.skye.colors.backgroundCard};
  width: 100%;
  justify-content: space-between;
  cursor: pointer;
`;
const ArrowIconWrapper = styled(BoxCentered)`
  width: 20px;
  height: 20px;
`;
const OptionsContainer = styled(Box)`
  flex-direction: column;

  .dropdown-item {
    padding: 8px 20px;
    height: auto;

    svg {
      path {
        stroke: ${(p: IAppContext) => p.theme.skye.colors.primary};
      }
    }
  }
`;

const handleSelect = (currentValue: any, callback: any) => () => {
  callback(currentValue);
};

const SelectDropdown = ({
  options,
  currentValue,
  placeholder,
  onSelect,
}: ISelectDropdownProptypes) => {
  const { t } = useTranslation();
  return (
    <SkyeDropdown
      onChange={() => {}}
      overlay={
        <DropdownWrapper>
          <OptionsContainer>
            {options.map((option) => (
              <DropdownItem
                key={option.value}
                onClick={handleSelect(option.value, onSelect)}
              >
                {!!option.icon && <IconWrapper>{option.icon}</IconWrapper>}
                <ContentWrapper>
                  <Title>{option.title}</Title>
                  {!!option.description && (
                    <Description>{option.description}</Description>
                  )}
                </ContentWrapper>
              </DropdownItem>
            ))}
          </OptionsContainer>
        </DropdownWrapper>
      }
    >
      <SelectContainer>
        <div
          className={classNames([
            "select-value text-sm font-normal",
            {
              "text-text-primary": currentValue,
              "text-text-placeholder": !currentValue,
            },
          ])}
        >
          {t(currentValue) || placeholder}
        </div>
        <ArrowIconWrapper>
          <ArrowDownIcon />
        </ArrowIconWrapper>
      </SelectContainer>
    </SkyeDropdown>
  );
};

export default SelectDropdown;
