import { SubscriptionPeriod } from "../../payments.model";
import {
  LITE_ANNUAL_PRICE,
  LITE_MONTHLY_PRICE,
} from "../Pricing/pricing.const";
import {
  CloudStorage,
  IPricing,
  IPricingSupport,
  PricingAccuracy,
  PricingOption,
  PricingProcessingSpeed,
} from "./PricingTable.model";

export const FREEMIUM_PRICING: IPricing = {
  name: "freemium",
  default: true,
  description: "Free high quality 2D and 3D modeling.",
  price: "Free Forever",
  options: {
    processingSpeed: PricingProcessingSpeed.Standard,
    accuracy: PricingAccuracy.Medium,
    interiorAiDesigner: PricingOption.NotApplied,
    toolsSharingAndExport: PricingOption.Applied,
    professionalTools: PricingOption.NotApplied,
    cjisCompliance: PricingOption.NotApplied,
    cloudStorage: CloudStorage.Month,
    support: IPricingSupport.Basic,
  },
};

export const PAY_PER_MODEL_PRICING: IPricing = {
  name: "payPerModel",
  description:
    "Set your work apart with actionable 3D models! Great for surveys, roof reports, and other workflows.",
  price: "Starts at $3 for files under 100mb",
  options: {
    processingSpeed: PricingProcessingSpeed.Standard,
    accuracy: PricingAccuracy.Medium,
    toolsSharingAndExport: PricingOption.Applied,
    interiorAiDesigner: PricingOption.NotApplied,
    professionalTools: PricingOption.Optional,
    cjisCompliance: PricingOption.Optional,
    cloudStorage: CloudStorage.OneYear,
    support: IPricingSupport.Email,
  },
};

export const LITE_PRICING = (
  subscriptionPeriod: SubscriptionPeriod
): IPricing => ({
  name: "liteSubscription",
  description:
    "Get access to all of your models and standard tools! Great for surveys, roof reports, real estate, and more.",
  price:
    subscriptionPeriod === SubscriptionPeriod.Monthly
      ? `$${LITE_MONTHLY_PRICE}/month`
      : `$${LITE_ANNUAL_PRICE}/month`,
  priceDescription:
    subscriptionPeriod === SubscriptionPeriod.Monthly
      ? "when billed monthly"
      : "when billed annually",
  options: {
    processingSpeed: PricingProcessingSpeed.Priority,
    accuracy: PricingAccuracy.High,
    toolsSharingAndExport: PricingOption.Applied,
    interiorAiDesigner: PricingOption.Applied,
    professionalTools: PricingOption.NotApplied,
    cjisCompliance: PricingOption.NotApplied,
    cloudStorage: CloudStorage.OneYear,
    support: IPricingSupport.Email,
  },
});

export const SUBSCRIPTION_PRICING = (
  subscriptionPeriod: SubscriptionPeriod
): IPricing => ({
  name: "subscription",
  description:
    "Designed for agencies that require the highest level of clarity, security, and feature sets.",
  price: "Request Quote",
  options: {
    processingSpeed: PricingProcessingSpeed.Ultra,
    accuracy: PricingAccuracy.Ultra,
    toolsSharingAndExport: PricingOption.Applied,
    interiorAiDesigner: PricingOption.Applied,
    professionalTools: PricingOption.Applied,
    cjisCompliance: PricingOption.Applied,
    cloudStorage: CloudStorage.FiveYears,
    support: IPricingSupport.CeoPhone,
  },
});

export const PREMIUM_PRICING_QUOTE_SUVERY_URL =
  "https://www.skyebrowse.com/pricing#SectionAboutQuote";
export const PRICING_URL = "https://www.skyebrowse.com/pricing";
export const DEFAULT_PRICINGS = [FREEMIUM_PRICING, PAY_PER_MODEL_PRICING];

export const TABLE_OPTIONS_ORDER = [
  "processingSpeed",
  "accuracy",
  "toolsSharingAndExport",
  "interiorAiDesigner",
  "professionalTools",
  "cjisCompliance",
  "cloudStorage",
  "support",
];
