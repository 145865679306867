import React, { useState } from 'react'
import { useTranslation } from 'react-i18next';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import userRequests from "../../../requests/user";
import AuthService from "../../../services/AuthService";
import EmptyListInformation from "../../shared/components/EmptyListInformation";
import Dialog from "../../shared/components/Dialog/Dialog";
import { USER_ROUTES } from "../../routes";
import BasePage from "../../shared/layouts/BasePage";
import InputGroup from "../../shared/form/InputGroup";
import { MessageService } from "../../../services/MessageService";

interface IUserDeletePagePropTypes extends RouteComponentProps {
  
}

const UserDeletePage = ({ history }: IUserDeletePagePropTypes) => {
  const { t } = useTranslation();
  const [modalOpen, setModalOpen] = useState(false);
  const [inputValue, setInputValue] = useState("");
  const [loading, setLoading] = useState(false);

  const canDelete = (): boolean => {
    return inputValue.length > 3;
  };

  const handleDeleteClick = () => {
    setInputValue("");
    setModalOpen(true);
  };

  const handleDialogClose = () => {
    setModalOpen(false);
  };

  const deleteAccount = () => {
    if (canDelete()) {
      setLoading(true);

      userRequests
        .deleteAccount(inputValue)
        .then(() => {
          history.push(USER_ROUTES.accountDeleted);
        })
        .catch((error) => {
          const msg = error?.data?.message || t("requestFailed");
          MessageService.error(msg);
        })
        .finally(() => {
          setLoading(false);
        });
    }
  };

  return (
    <BasePage backgroundColor="white">
      <Dialog
        title={t("deleteAccount")}
        open={modalOpen}
        onClose={handleDialogClose}
        confirmButtonProps={{
          loading,
          color: "danger",
          children: t("deleteAccount"),
          disabled: !canDelete(),
          onClick: deleteAccount,
        }}
      >
        <InputGroup
          bordered
          inputProps={{
            value: inputValue,
            placeholder: t("deleteAccountInputPlaceholder"),
            onChange: (e: any) => setInputValue(e.target.value),
          }}
        />
      </Dialog>
      <EmptyListInformation
        title={"userDeletePageHeading"}
        description={"userDeletePageDescription"}
        buttonProps={{
          onClick: handleDeleteClick,
          children: t("deleteAccount"),
          color: "danger",
        }}
        showButton
      ></EmptyListInformation>
    </BasePage>
  );
};

export default withRouter(UserDeletePage);
