import React, { useState } from "react";
import SkyebrowseAuthPageLayout from "../layout";
import Button from "../../../shared/buttons/Button";
import InformationBox from "../../../shared/components/InformationBox/InformationBox";
import { RouteComponentProps, withRouter } from "react-router-dom";
import { AUTH_ROUTES } from "../../../routes";
import { useTranslation } from "react-i18next";
import RegisterTokenInitForm from "../../templates/register-form";

interface IResetPasswordPagePropTypes extends RouteComponentProps {}

const RegisterTokenInitPage = ({ history }: IResetPasswordPagePropTypes) => {
  const { t } = useTranslation();
  const [registered, setRegistered] = useState(false);

  const onRegistered = () => {
    setRegistered(true);
  };

  const goToLogin = () => {
    history.push(AUTH_ROUTES.login);
  };

  return (
    <SkyebrowseAuthPageLayout
      title=""
      pageType="finishRegisterByToken"
      contentSize="lg"
    >
      {registered ? (
        <InformationBox
          transparent
          icon="success"
          title={"Registration success"}
          description="Please check your email to verify your account."
          button={<Button onClick={goToLogin}>Go to login</Button>}
        ></InformationBox>
      ) : (
        <>
          <div className="max-w-md flex justify-center flex-col items-center w-full mx-auto">
            <h1 className="mb-20 text-4xl font-bold text-center">
              {registered ? (
                ""
              ) : (
                <span>
                  Make unlimited 3D <br />
                  models for free!
                </span>
              )}
            </h1>
            <RegisterTokenInitForm onRegistered={onRegistered} />
          </div>
        </>
      )}
    </SkyebrowseAuthPageLayout>
  );
};

export default withRouter(RegisterTokenInitPage);
