import Joi from "joi";

export const userDataWizardFormSchema = Joi.object({
  firstName: Joi.string().required(),
  lastName: Joi.string().required(),
  organizationName: Joi.string().required(),
  phone: Joi.string().required(),
  jobTitle: Joi.string().required(),
  registrationSource: Joi.optional(),
});
