export default class Volume {
  static add() {
    if (window.viewer && window.viewer.volumetricTool) {
      window.viewer.volumetricTool.startInsertion();
    } else {
      console.error("Viewer or VolumeTool not initialized.");
    }
  }

  static deleteAll() {
    if (window.viewer && window.viewer.scene && window.viewer.scene.volumes) {
      window.viewer.scene.volumes.forEach((volume) => {
        window.viewer.scene.removeVolume(volume);
      });
    } else {
      console.error("Viewer or scene volumes not initialized.");
    }
  }
}
